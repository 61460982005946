import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function UserList({ token }) {
    const [users, setUsers] = useState([]);
    const [showOnlyUnnotified, setShowOnlyUnnotified] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate('/login');
        } else {
            axios.get('https://aws-solutions.gr:8443/api/notifications', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(response => {
                    setUsers(response.data);
                })
                .catch(error => {
                    console.error('There was an error fetching the users!', error);
                });
        }
    }, [token, navigate]);

    const markAsNotified = (userId) => {
        axios.put(`https://aws-solutions.gr:8443/api/notifications/${userId}/notified?isNotified=true`, null, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setUsers(users.map(user =>
                    user.id === userId ? { ...user, notified: true } : user
                ));
                alert(`User ${userId} marked as notified`);
            })
            .catch(error => {
                console.error('There was an error updating the notification status!', error);
            });
    };

    const deleteNotification = (userId) => {
        axios.delete(`https://aws-solutions.gr:8443/api/notifications/${userId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setUsers(users.filter(user => user.id !== userId));
                alert(`User ${userId} deleted`);
            })
            .catch(error => {
                console.error('There was an error deleting the notification!', error);
            });
    };

    const formatDate = (dateString) => {
        if (!dateString) {
            return ''; // Return an empty string if dateString is null or undefined
        }
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    const filteredUsers = showOnlyUnnotified ? users.filter(user => !user.notified) : users;

    return (
        <div className="UserList">
            <h1>User List</h1>
            <Button
                variant="primary"
                onClick={() => setShowOnlyUnnotified(!showOnlyUnnotified)}
                className="mb-3"
            >
                {showOnlyUnnotified ? 'Show All Users' : 'Show Only Unnotified'}
            </Button>
            <div className="d-none d-md-block">
                {/* Desktop Table View */}
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Email</th>
                        <th>Product Name</th>
                        <th>Actions</th>
                        <th>Notified</th>
                        <th>Date Requested</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredUsers.map(user => (
                        <tr key={user.id} style={{ textDecoration: user.notified ? 'line-through' : 'none' }}>
                            <td>{user.id}</td>
                            <td>{user.email}</td>
                            <td>{user.productName}</td>
                            <td>
                                <Button
                                    variant="success"
                                    onClick={() => markAsNotified(user.id)}
                                    disabled={user.notified}
                                >
                                    Mark as Notified
                                </Button>{' '}
                                <Button variant="danger" onClick={() => deleteNotification(user.id)}>Delete</Button>
                            </td>
                            <td style={{ color: user.notified ? 'green' : 'red' }}>
                                {user.notified ? 'Yes' : 'No'}
                            </td>
                            <td>{formatDate(user.dateRequested)}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>

            <div className="d-block d-md-none">
                {/* Mobile Card View */}
                {filteredUsers.map(user => (
                    <Card key={user.id} className="mb-3" style={{ textDecoration: user.notified ? 'line-through' : 'none' }}>
                        <Card.Body>
                            <Card.Title>ID: {user.id}</Card.Title>
                            <Card.Text><strong>Email:</strong> {user.email}</Card.Text>
                            <Card.Text><strong>Product Name:</strong> {user.productName}</Card.Text>
                            <Card.Text><strong>Notified:</strong> <span style={{ color: user.notified ? 'green' : 'red' }}>{user.notified ? 'Yes' : 'No'}</span></Card.Text>
                            <Card.Text><strong>Date Requested:</strong> {formatDate(user.dateRequested)}</Card.Text>
                            <Button
                                variant="success"
                                onClick={() => markAsNotified(user.id)}
                                disabled={user.notified}
                                className="me-2"
                            >
                                Mark as Notified
                            </Button>
                            <Button variant="danger" onClick={() => deleteNotification(user.id)}>Delete</Button>
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </div>
    );
}

export default UserList;
