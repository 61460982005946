// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import UserList from './components/UserList';
import Navigation from './components/Navbar';

function App() {
    const [token, setToken] = useState(localStorage.getItem('token'));

    const handleSetToken = (newToken) => {
        setToken(newToken);
        localStorage.setItem('token', newToken);
    };

    return (
        <Router>
            <div className="App">
                <Navigation />
                <Routes>
                    <Route path="/login" element={<Login setToken={handleSetToken} />} />
                    <Route path="/users" element={<UserList token={token} />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
